(function() {
    'use strict';

    wo$(function() {

        var page = wo$('#login');
        if (!page.length) return;
        var clientData = WhistleOut.readLookupData(page);

        var controller = {

            init: function () {
                controller.bind();
            },

            bind: function() {
                page.find('#username').focus();

                page.find('#form').submit(function (event) {
                    event.preventDefault();
                    controller.submit();
                });
            },

            submit: function () {
                page.find('#error').hide();
                page.find('#submit').attr('disabled', '');
                page.find('#submit span').show();

                var data = {
                    username: page.find('#username').val(),
                    password: page.find('#password').val(),
                    returnUrl: clientData.returnUrl
                };

                wo$.ajax({
                    url: '/Ajax/Shared/General/PerformLogin',
                    data: data,
                    dataType: 'text',
                    type: 'POST',
                    success: function (result) {
                        if (result === '')
                            controller.error(clientData.loginFailed);
                        else {
                            window.location = result;
                        }
                    },
                    error: function () {
                        controller.error(clientData.error);
                    }
                });
            },

            error: function(message) {
                page.find('#error').html(message).show();
                page.find('#submit').removeAttr('disabled');
                page.find('#submit span').hide();
            }

        };

        controller.init();

    });

}());
